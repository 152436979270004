import React, { useEffect } from "react";
import { Col, Row } from "reactstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaMinus, FaPlus } from 'react-icons/fa';
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import { translate } from 'utils/translate';
import { connect } from 'react-redux';
import { LazyLoadComponent, LazyLoadImage } from "react-lazy-load-image-component";
import ReactHtmlParser from 'react-html-parser';

const faqAccordion = [
    {
        title: '护照扫描',
        desc: `<ul>
                    <li>每位申请人的<b>整本</b>护照扫描</li>
                    <li>所有申请人护照必须超过<b>24</b>个月有效期</li>
                    <li>护照记录少于一年以下，必须提供整本旧护照一同扫描</li>
                </ul>`
    },
    {
        title: '护照标准照',
        desc: `<ul>
                    <li>标准照（蓝/白底，5.5cm x 3.5cm）- 人头至肩膀</li>
                    <li>每位申请人需提供电子版即可</li>
                </ul>`
    },
    {
        title: '个人简历',
        desc: `<ul>
                    <li>主申请人和配偶（如有陪同）教育背景及10年的工作简历</li>
                    <li>我们提供简历表格</li>
                </ul>`
    },
    {
        title: '无犯罪记录',
        desc: `<ul>
                    <li>主申请人、配偶以及父母无犯罪记录（孩子年龄18岁或以上也必须无犯罪记录）</li>
                    <li>翻译+公证+马来西亚使馆认证（双认证）</li>
                </ul>`
    },
    {
        title: '结婚证/出生证',
        desc: `<ul>
                    <li>提供结婚证，孩子出生证，父母关系证明</li>
                    <li>翻译+公证+马来西亚使馆认证（双认证）</li>
                </ul>`
    },
    {
        title: '资产证明',
        desc: `<ul>
                    <li>150万马币的定期存款证明</li>
                    <li>银行流水单(至少3个月与工资单一致)或其他文件正本或公证正本,例如:金存款证明,定期存款等。</li>
                </ul>`
    },
    // {
    //     title: '存款证明',
    //     desc: ''
    // }
]

function MM2HPlan(props) {
    const [selectedContent, setSelectedContent] = React.useState(0);
    const [imageIndex, setImageIndex] = React.useState(0);

    const settings = {
        dots: false,
        infinite: false,
        lazyLoad: 'ondemand',
        initOnLoad: true,
        nextSlidesToPreload: 1,
        autoplay: false,
        speed: 300,
        slidesToShow: window.innerWidth > 992 ? 3 : window.innerWidth > 677 ? 2 : 1.3,
        centerMode: false,
        centerPadding: 0,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        beforeChange: (current, next) => {
            setImageIndex(next);
        },
    };

    function SampleNextArrow(props) {
        const { className, onClick } = props;
        return (
            <div className={className} onClick={onClick}>
                <LazyLoadImage src="assets/img/home/nextArrow.webp" className="nextArrow" alt="next" />
            </div>
        );
    }

    function SamplePrevArrow(props) {
        const { className, onClick } = props;
        return (
            <div className={className} onClick={onClick}>
                <LazyLoadImage src="assets/img/home/prevArrow.webp" className="prevArrow" alt="prev" />
            </div>
        );
    }

    useEffect(() => {
        if (document.getElementById("mm2hLine1") && window.innerWidth > 991) {
            if (imageIndex === 0) {
                document.getElementById("mm2hLine1").style.display = "block";
                document.getElementById("mm2hLine2").style.display = "block";
                document.getElementById("mm2hLine3").style.display = "none !important";
            }
            else if (imageIndex === 1) {
                document.getElementById("mm2hLine1").style.display = "none";
                document.getElementById("mm2hLine2").style.display = "block";
                document.getElementById("mm2hLine3").style.display = "block";
                document.getElementById("mm2hLine4").style.display = "none";
            }
            else if (imageIndex === 2) {
                document.getElementById("mm2hLine1").style.display = "none";
                document.getElementById("mm2hLine2").style.display = "none";
                document.getElementById("mm2hLine3").style.display = "block";
                document.getElementById("mm2hLine4").style.display = "block";
                document.getElementById("mm2hLine5").style.display = "none";
            }
            // else if (imageIndex === 3) {
            //     document.getElementById("mm2hLine1").style.display = "none";
            //     document.getElementById("mm2hLine2").style.display = "none";
            //     document.getElementById("mm2hLine3").style.display = "none";
            //     document.getElementById("mm2hLine4").style.display = "block";
            //     document.getElementById("mm2hLine5").style.display = "block";
            // }
        }
    }, [imageIndex]);


    return (
        <>
            <div className="min-height-100 mm2hPlan-outer" id="mm2hPlan">
                <div className="mm2hPlan h-100 w-100">
                    {/* <LazyLoadComponent> */}
                        <div className="bg-image" />
                    {/* </LazyLoadComponent> */}

                    <Row className="justify-content-center align-items-center py-5 mx-0">
                        <Col lg={10} md={11} sm={11} xs={11}>
                            <div className="text-center pt-4 pt-lg-0">
                                <span className="d-block mm2hTitle mb-1">{translate(props.lang, "MM2H计划申请流程")}</span>
                            </div>
                        </Col>

                        <Col lg={10} md={11} sm={12} xs={12} className="px-0 px-md-3 pt-5">
                            <Slider {...settings} arrows={true}>
                                <div className="text-center px-md-0 px-lg-5 mm2hItem">
                                    <div className="mm2hItemBg h-100">
                                        <div className="px-2 px-md-5">
                                            <LazyLoadImage src="assets/img/home/mm2hPlan1.webp" className="mb-3 mx-auto" alt="mm2hPlan" />
                                            <span className="mm2hItemText d-block mb-3">{translate(props.lang, "签意向书")}</span>
                                            <span className="mm2hItemDescText d-block px-4">{translate(props.lang, "同意易马海外YMG (MM2H) SDN BHD作为办代理并先交费20%+参与费")}</span>
                                        </div>
                                    </div>

                                    <div className="mm2hItemIndex">
                                        <span className="">1</span>
                                    </div>

                                    <div className="d-none d-lg-block">
                                        <div className="mm2hLineDiv" id="mm2hLine1">
                                            <LazyLoadImage src="assets/img/home/mm2hLine.webp" className="px-0 mx-0 mm2hLine" alt="mm2hLine" />
                                        </div>
                                    </div>
                                </div>

                                <div className="text-center px-md-0 px-lg-5 mm2hItem">
                                    <div className="mm2hItemBg h-100">
                                        <div className="px-2 px-md-5">
                                            <LazyLoadImage src="assets/img/home/mm2hPlan2.webp" className="mb-3 mx-auto" alt="mm2hPlan" />
                                            <span className="mm2hItemText d-block mb-3">{translate(props.lang, "准备并提交文件")}</span>
                                            <span className="mm2hItemDescText d-block px-4">{translate(props.lang, "准备资料并提交申请，获得回执")}</span>
                                        </div>
                                    </div>

                                    <div className="mm2hItemIndex">
                                        <span className="">2</span>
                                    </div>

                                    <div className="d-none d-lg-block">
                                        <div className="mm2hLineDiv" id="mm2hLine2">
                                            <LazyLoadImage src="assets/img/home/mm2hLine.webp" className="px-0 mx-0 mm2hLine" alt="mm2hLine" />
                                        </div>
                                    </div>
                                </div>

                                <div className="text-center px-md-0 px-lg-5 mm2hItem">
                                    <div className="mm2hItemBg h-100">
                                        <div className="px-2 px-md-5">
                                            <LazyLoadImage src="assets/img/home/mm2hPlan3.webp" className="mb-3 mx-auto" alt="mm2hPlan" />
                                            <span className="mm2hItemText d-block mb-3">{translate(props.lang, "获取批文")}</span>
                                            <span className="mm2hItemDescText d-block px-4">{translate(props.lang, "等待申请通过，获得批文，14天内交付尾款")}</span>
                                        </div>
                                    </div>

                                    <div className="mm2hItemIndex">
                                        <span className="">3</span>
                                    </div>

                                    <div className="d-none d-lg-block">
                                        <div className="mm2hLineDiv" id="mm2hLine3">
                                            <LazyLoadImage src="assets/img/home/mm2hLine.webp" className="px-0 mx-0 mm2hLine" alt="mm2hLine" />
                                        </div>
                                    </div>
                                </div>

                                <div className="text-center px-md-0 px-lg-5 mm2hItem">
                                    <div className="mm2hItemBg h-100">
                                        <div className="px-2 px-md-5">
                                            <LazyLoadImage src="assets/img/home/mm2hPlan4.webp" className="mb-3 mx-auto" alt="mm2hPlan" />
                                            <span className="mm2hItemText d-block mb-3">{translate(props.lang, "安排贴签")}</span>
                                            <span className="mm2hItemDescText d-block px-4">{translate(props.lang, "安排来马来西亚报到第二家园，办理第二家园定期存款，体检，上保险及交付第三方费用并带护照到移民局贴签")}</span>
                                        </div>
                                    </div>

                                    <div className="mm2hItemIndex">
                                        <span className="">4</span>
                                    </div>

                                    <div className="d-none d-lg-block">
                                        <div className="mm2hLineDiv" id="mm2hLine4">
                                            <LazyLoadImage src="assets/img/home/mm2hLine.webp" className="px-0 mx-0 mm2hLine" alt="mm2hLine" />
                                        </div>
                                    </div>
                                </div>

                                <div className="text-center px-md-0 px-lg-5 mm2hItem">
                                    <div className="mm2hItemBg h-100">
                                        <div className="px-2 px-md-5">
                                            <LazyLoadImage src="assets/img/home/mm2hPlan5.webp" className="mb-3 mx-auto" alt="mm2hPlan" />
                                            <span className="mm2hItemText d-block mb-3">{translate(props.lang, "完成")}</span>
                                            <span className="mm2hItemDescText d-block px-4">{translate(props.lang, "安排选购房子")}</span>
                                        </div>
                                    </div>

                                    <div className="mm2hItemIndex">
                                        <span className="">5</span>
                                    </div>

                                    <div className="d-none d-lg-block">
                                        <div className="mm2hLineDiv" id="mm2hLine5">
                                            <LazyLoadImage src="assets/img/home/mm2hLine.webp" className="px-0 mx-0 mm2hLine" alt="mm2hLine" />
                                        </div>
                                    </div>
                                </div>
                            </Slider>
                        </Col>
                    </Row>
                    <Row className="justify-content-start align-items-start mx-0">
                        <Col lg={1} md={12} sm={12} xs={12} />

                        <Col lg={5} md={12} sm={11} xs={11}>
                            <span className="accordionSectionTitle ml-4 ml-md-1">{translate(props.lang, "申请所需文件")}</span>
                        </Col>
                    </Row>
                    <Row className="justify-content-center align-items-start accordionPosition mx-0">
                        <Col lg={5} md={6} sm={11} xs={11}>
                            <Accordion preExpanded={[0]} className="text-left borderTopWhite">
                                {faqAccordion.map((item, i) => (
                                    i >= 3 ? "" :
                                        <AccordionItem key={i} uuid={i} className={i === selectedContent ? "accordion-item borderBottomWhite" : "accordion-item-inactive borderBottomWhite"} onClick={() => setSelectedContent(i)}>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    <div className="row justify-content-center align-items-center">
                                                        <div className="col-10 px-1">
                                                            <div className="">
                                                                <span className={i === selectedContent ? "accordionTitle lh-1" : "accordionTitle-inactive lh-1"}>
                                                                    {translate(props.lang, item.title)}
                                                                </span>

                                                            </div>
                                                        </div>
                                                        <div className="col-2">
                                                            <div className="text-right">
                                                                <FaMinus className={`h3 my-0 accordionIcon ${i === selectedContent ? "selectedExpandIcon" : "nonselectedExpandIcon"}`} style={{ display: i === selectedContent ? "block" : "none" }} />
                                                                <FaPlus className={`h3 my-0 accordionIcon ${i === selectedContent ? "nonselectedExpandIcon" : "selectedExpandIcon"}`} style={{ display: i === selectedContent ? "none" : "block" }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel style={{ display: i === selectedContent ? "block" : "none" }}>
                                                <div className="row justify-content-start align-items-center">
                                                    <div className="col-11">
                                                        <span className="accordionContent">
                                                            {ReactHtmlParser(item?.desc)}
                                                        </span>
                                                    </div>
                                                </div>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                ))}
                            </Accordion>
                        </Col>

                        <Col lg={5} md={6} sm={11} xs={11}>
                            <Accordion preExpanded={[0]} className="text-left borderTopWhite">
                                {faqAccordion.map((item, i) => (
                                    i < 3 ? "" :
                                        <AccordionItem key={i} uuid={i} className={i === selectedContent ? "accordion-item borderBottomWhite" : "accordion-item-inactive borderBottomWhite"} onClick={() => setSelectedContent(i)}>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    <div className="row justify-content-center align-items-center">
                                                        <div className="col-10 px-1">
                                                            <div className="">
                                                                <span className={i === selectedContent ? "accordionTitle lh-1" : "accordionTitle-inactive lh-1"}>
                                                                    {translate(props.lang, item.title)}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="col-2">
                                                            <div className="text-right">
                                                                <FaMinus className={`h3 my-0 accordionIcon ${i === selectedContent ? "selectedExpandIcon" : "nonselectedExpandIcon"}`} style={{ display: i === selectedContent ? "block" : "none" }} />
                                                                <FaPlus className={`h3 my-0 accordionIcon ${i === selectedContent ? "nonselectedExpandIcon" : "selectedExpandIcon"}`} style={{ display: i === selectedContent ? "none" : "block" }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel style={{ display: i === selectedContent ? "block" : "none" }}>
                                                <div className="row justify-content-start align-items-center">
                                                    <div className="col-12">
                                                        <span className="accordionContent">
                                                            {ReactHtmlParser(item?.desc)}
                                                        </span>

                                                        {/* {
                                                            selectedContent === 5 ?
                                                                <>
                                                                    <table class="table table-bordered w-100 d-none d-lg-block">
                                                                        <thead>
                                                                            <tr>
                                                                                <th scope="col" style={{width:"18%"}} className="table-titleBg tableDescText text-center font-weight-bold">白银级</th>
                                                                                <th scope="col" style={{width:"18%"}} className="table-titleBg tableDescText text-center font-weight-bold">黄金级</th>
                                                                                <th scope="col" style={{width:"18%"}} className="table-titleBg tableDescText text-center font-weight-bold">铂金级</th>
                                                                                <th scope="col" style={{width:"30%"}} className="table-titleBg tableDescText text-center font-weight-bold">经济特区（柔佛特区）</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td className="text-white text-center tableDescText">建议150万人民币</td>
                                                                                <td className="text-white text-center tableDescText">建议400万人民币</td>
                                                                                <td className="text-white text-center tableDescText">建议800万人民币</td>
                                                                                <td className="text-white text-center tableDescText">
                                                                                    <span className="d-block">
                                                                                        21-49岁：建议60万人民币
                                                                                    </span>
                                                                                    50岁以上：建议30万人民币
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>

                                                                    <div className="w-100">
                                                                        <table class="table table-bordered w-100 d-block d-lg-none mb-0">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th scope="col" className="table-titleBg tableDescText text-center font-weight-bold">白银级</th>
                                                                                    <th scope="col" className="table-titleBg tableDescText text-center font-weight-bold">黄金级</th>
                                                                                    <th scope="col" className="table-titleBg tableDescText text-center font-weight-bold">铂金级</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td className="text-white text-center tableDescText">建议150万人民币</td>
                                                                                    <td className="text-white text-center tableDescText">建议400万人民币</td>
                                                                                    <td className="text-white text-center tableDescText">建议800万人民币</td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                        <table class="table table-bordered w-100 d-block d-lg-none">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th style={{width: "10%"}} className="table-titleBg tableDescText text-center font-weight-bold">经济特区（柔佛特区）</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td className="text-white text-center tableDescText">
                                                                                        <span className="d-block">
                                                                                            21-49岁：建议60万人民币
                                                                                        </span>
                                                                                        50岁以上：建议30万人民币
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </>
                                                                : null
                                                        } */}
                                                    </div>
                                                </div>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                ))}
                            </Accordion>
                        </Col>
                    </Row>
                </div>
            </div >
        </>
    );
}

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}
export default connect(mapStateToProps)(MM2HPlan);
import React from "react";
import { Col, Row } from "reactstrap";
import { translate } from 'utils/translate';
import { connect } from 'react-redux';
import { LazyLoadComponent, LazyLoadImage } from "react-lazy-load-image-component";

function SecondHome(props) {
    return (
        <>
            <div className="min-height-100 secondHomeSection bg-image-parent">
                <img src="assets/img/home/secondHomeBg2.webp" className="bg-image" alt="secondHomeBg2"/>



                <div className="d-none d-lg-block">
                    <Row className="justify-content-center align-items-start mx-0 h-100">
                        <Col lg={10} md={11} sm={11} xs={11} className="mb-5">
                            <div className="secondHomeTitle text-center">
                                <span className="d-block mb-1">{translate(props.lang, "为何选择")}</span>
                                <span className="d-block">{translate(props.lang, "马来西亚第二家园")}</span>
                            </div>
                        </Col>

                        <Col lg={10} md={12} sm={12} xs={12}>
                            <Row className="justify-content-center align-items-center mx-0 h-100 secondHomeContent">
                                <Col lg={4} md={4} sm={11} xs={11} className="px-0 position-relative" id="secondHomeSection1">
                                <LazyLoadComponent>
                                <div className="bg-image" style={{borderRadius:'inherit'}}/>
                                </LazyLoadComponent>
                                    <div className="d-flex align-items-end w-100 h-100 secondHomeInner px-4">

                                    </div>
                                    <div className="descSection">
                                        <span className="d-block">{translate(props.lang, "灵活自由低门槛可撤回的侨居条件")}</span>
                                    </div>
                                    <div className="pinSection">
                                        <LazyLoadImage src="assets/img/home/secondHomePIN.webp" className="" />
                                    </div>
                                </Col>

                                <Col lg={4} md={4} sm={11} xs={11} className="px-0 position-relative" id="secondHomeSection2">
                                <LazyLoadComponent>
                                <div className="bg-image" style={{borderRadius:'inherit'}}/>
                                </LazyLoadComponent>
                                    <div className="d-flex align-items-end w-100 h-100 secondHomeInner px-4">

                                    </div>
                                    <div className="descSection">
                                        <span className="d-block">{translate(props.lang, "无英语要求、无须资产来源证明")}</span>
                                    </div>
                                    <div className="pinSection">
                                        <LazyLoadImage src="assets/img/home/secondHomePIN.webp" className="" />
                                    </div>
                                </Col>

                                <Col lg={4} md={4} sm={11} xs={11} className="px-0 position-relative" id="secondHomeSection3">
                                <LazyLoadComponent>
                                <div className="bg-image" style={{borderRadius:'inherit'}}/>
                                </LazyLoadComponent>
                                    <div className="d-flex align-items-end w-100 h-100 secondHomeInner px-4">
                                        <span className="d-block mb-4"></span>
                                    </div>
                                    <div className="descSection">
                                        <span className="d-block">{translate(props.lang, "最高20签证，可无限续签")}</span>
                                    </div>
                                    <div className="pinSection">
                                        <LazyLoadImage src="assets/img/home/secondHomePIN.webp" className="" />
                                    </div>
                                </Col>

                                <Col lg={3} md={3} sm={11} xs={11} className="px-0 position-relative" id="secondHomeSection4">
                                <LazyLoadComponent>
                                <div className="bg-image" style={{borderRadius:'inherit'}}/>
                                </LazyLoadComponent>
                                    <div className="d-flex align-items-end w-100 h-100 secondHomeInner px-5">

                                    </div>
                                    <div className="descSection">
                                        <span className="d-block">{translate(props.lang, "办理快、周期短(4-8月)办妥即用")}</span>
                                    </div>
                                    <div className="pinSection">
                                        <LazyLoadImage src="assets/img/home/secondHomePIN.webp" className="" />
                                    </div>
                                </Col>

                                <Col lg={3} md={3} sm={11} xs={11} className="px-0 position-relative" id="secondHomeSection5">
                                <LazyLoadComponent>
                                <div className="bg-image" style={{borderRadius:'inherit'}}/>
                                </LazyLoadComponent>
                                    <div className="d-flex align-items-end w-100 h-100 secondHomeInner px-4">

                                    </div>
                                    <div className="descSection">
                                        <span className="d-block">{translate(props.lang, "仅需存入大马银行最低 15万马币定存，第二年后最高提款额是50%，以作为买房费用、孩子教育费和医疗费用等。无须放弃国内身份、事业、福利和社会关系。身份可撤回，仅需取回存款无损失")}</span>
                                    </div>
                                    <div className="pinSection">
                                        <LazyLoadImage src="assets/img/home/secondHomePIN.webp" className="" />
                                    </div>
                                </Col>

                                <Col lg={3} md={3} sm={11} xs={11} className="px-0 position-relative" id="secondHomeSection6">
                                <LazyLoadComponent>
                                <div className="bg-image" style={{borderRadius:'inherit'}}/>
                                </LazyLoadComponent>
                                    <div className="d-flex align-items-end w-100 h-100 secondHomeInner px-4">

                                    </div>
                                    <div className="descSection">
                                        <span className="d-block">{translate(props.lang, "一人主申请，全家可获签，父母可随行，并可带一佣人进入马来西亚。")}</span>
                                    </div>
                                    <div className="pinSection">
                                        <LazyLoadImage src="assets/img/home/secondHomePIN.webp" className="" />
                                    </div>
                                </Col>

                                <Col lg={3} md={3} sm={11} xs={11} className="px-0 position-relative" id="secondHomeSection7">
                                <LazyLoadComponent>
                                <div className="bg-image" style={{borderRadius:'inherit'}}/>
                                </LazyLoadComponent>
                                    <div className="d-flex align-items-end w-100 h-100 secondHomeInner px-4">

                                    </div>
                                    <div className="descSection">
                                        <span className="d-block descText">{translate(props.lang, "子女就学，父母前往陪读。")}</span>
                                    </div>
                                    <div className="pinSection">
                                        <LazyLoadImage src="assets/img/home/secondHomePIN.webp" className="" />
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>

                <div className="d-block d-lg-none">
                    <Row className="justify-content-center align-items-start mx-0 h-100">
                        <Col lg={10} md={11} sm={11} xs={11} className="mb-5">
                            <div className="secondHomeTitle text-center">
                                <span className="d-block mb-1">{translate(props.lang, "为何选择")}</span>
                                <span className="d-block">{translate(props.lang, "马来西亚第二家园")}</span>
                            </div>
                        </Col>
                    </Row>
                    <div className="horizontalDragOuter">
                        <div className="horizontalDrag">

                            <div className="px-0 position-relative" id="secondHomeSection1"> 

                                <LazyLoadComponent>
                                <div className="bg-image" style={{borderRadius:'inherit'}}/>
                                </LazyLoadComponent>

                                <div className="d-flex align-items-end w-100 h-100 secondHomeInner px-4">

                                </div>
                                <div className="descSection">
                                    <span className="d-block">{translate(props.lang, "灵活自由低门槛可撤回的侨居条件")}</span>
                                </div>
                                <div className="pinSection">
                                    <LazyLoadImage src="assets/img/home/secondHomePIN.webp" className="" />
                                </div>
                            </div>

                            <div className="px-0 position-relative" id="secondHomeSection2">
                            
                            <LazyLoadComponent>
                                <div className="bg-image" style={{borderRadius:'inherit'}}/>
                                </LazyLoadComponent>

                                <div className="d-flex align-items-end w-100 h-100 secondHomeInner px-4">

                                </div>
                                <div className="descSection">
                                    <span className="d-block">{translate(props.lang, "无英语要求、无须资产来源证明")}</span>
                                </div>
                                <div className="pinSection">
                                    <LazyLoadImage src="assets/img/home/secondHomePIN.webp" className="" />
                                </div>
                            </div>

                            <div className="px-0 position-relative" id="secondHomeSection3">
                            <LazyLoadComponent>
                                <div className="bg-image" style={{borderRadius:'inherit'}}/>
                                </LazyLoadComponent>
                                <div className="d-flex align-items-end w-100 h-100 secondHomeInner px-4">

                                </div>
                                <div className="descSection">
                                    <span className="d-block">{translate(props.lang, "最高20签证，可无限续签")}</span>
                                </div>
                                <div className="pinSection">
                                    <LazyLoadImage src="assets/img/home/secondHomePIN.webp" className="" />
                                </div>
                            </div>

                            <div className="px-0 position-relative" id="secondHomeSection4">
                            <LazyLoadComponent>
                                <div className="bg-image" style={{borderRadius:'inherit'}}/>
                                </LazyLoadComponent>
                                <div className="d-flex align-items-end w-100 h-100 secondHomeInner px-4">
                                </div>
                                <div className="descSection">
                                    <span className="d-block">{translate(props.lang, "办理快、周期短(4-8月)办妥即用")}</span>
                                </div>
                                <div className="pinSection">
                                    <LazyLoadImage src="assets/img/home/secondHomePIN.webp" className="" />
                                </div>
                            </div>

                            <div className="px-0 position-relative" id="secondHomeSection5">
                            <LazyLoadComponent>
                                <div className="bg-image" style={{borderRadius:'inherit'}}/>
                                </LazyLoadComponent>
                                <div className="d-flex align-items-end w-100 h-100 secondHomeInner px-4">

                                </div>
                                <div className="descSection">
                                    <span className="d-block">{translate(props.lang, "仅需存入大马银行最低 15万马币定存，第二年后最高提款额是50%，以作为买房费用、孩子教育费和医疗费用等。无须放弃国内身份、事业、福利和社会关系。身份可撤回，仅需取回存款无损失")}</span>
                                </div>
                                <div className="pinSection">
                                    <LazyLoadImage src="assets/img/home/secondHomePIN.webp" className="" />
                                </div>
                            </div>

                            <div className="px-0 position-relative" id="secondHomeSection6">
                            <LazyLoadComponent>
                                <div className="bg-image" style={{borderRadius:'inherit'}}/>
                                </LazyLoadComponent>
                                <div className="d-flex align-items-end w-100 h-100 secondHomeInner px-4">

                                </div>
                                <div className="descSection">
                                    <span className="d-block">{translate(props.lang, "一人主申请，全家可获签，父母可随行，并可带一佣人进入马来西亚。")}</span>
                                </div>
                                <div className="pinSection">
                                    <LazyLoadImage src="assets/img/home/secondHomePIN.webp" className="" />
                                </div>
                            </div>

                            <div className="px-0 position-relative" id="secondHomeSection7">
                            <LazyLoadComponent>
                                <div className="bg-image" style={{borderRadius:'inherit'}}/>
                                </LazyLoadComponent>
                                <div className="d-flex align-items-end w-100 h-100 secondHomeInner px-4">

                                </div>
                                <div className="descSection">
                                    <span className="d-block">{translate(props.lang, "子女就学，父母前往陪读。")}</span>
                                </div>
                                <div className="pinSection">
                                    <LazyLoadImage src="assets/img/home/secondHomePIN.webp" className="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}
export default connect(mapStateToProps)(SecondHome);
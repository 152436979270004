import React from "react";
import { Col, Row } from "reactstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { translate } from 'utils/translate';
import { connect } from 'react-redux';
import { LazyLoadComponent, LazyLoadImage } from 'react-lazy-load-image-component';

function SecondHomeLevel(props) {
    const [imageIndex2, setImageIndex2] = React.useState(0);
    const slider = React.useRef(null);

    const settings2 = {
        dots: false,
        infinite: false,
        swipe: true,
        // lazyLoad: "ondemand",
        // initOnLoad: true,
        // nextSlidesToPreload: 1,
        // autoplay: true,
        speed: 300,
        slidesToShow: window.innerWidth > 992 ? 1.5 : 1,
        draggable: false,
        centerMode: false,
        centerPadding: 0,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        beforeChange: (current, next) => {
            setImageIndex2(next);
        },
    };

    function SampleNextArrow(props) {
        const { className, onClick } = props;
        return (
            <div className={className} onClick={onClick}>
                <LazyLoadImage
                    src="assets/img/home/right.webp"
                    className="nextArrow"
                    alt="next"
                />
            </div>
        );
    }

    function SamplePrevArrow(props) {
        const { className, onClick } = props;
        return (
            <div className={className} onClick={onClick}>
                <LazyLoadImage
                    src="/assets/img/home/left.webp"
                    className="prevArrow"
                    alt="prev"
                />
            </div>
        );
    }

    // React.useEffect(() => {
    //     var heightTemp = document.getElementById('secondHomeCarouselItemHeight').clientHeight;
    //     document.getElementById('secondHomeLevelSliderBg').style.height = heightTemp;
    // });

    return (
        <>
            <div className="secondHomeLevel">
                <div className="secondHomeLevelBg position-relative">
                    <Row className="justify-content-center justify-content-lg-end align-items-start mx-0 h-100 position-relative">
                        <Col lg={10} md={12} sm={12} xs={12} className="">
                            <Row className="justify-content-end">
                                <Col lg={12} md={9} sm={10} xs={10} className="px-0">
                                    <div className="secondHomeLevelSliderBg" id="secondHomeLevelSliderBg"></div>
                                </Col>
                            </Row>
                            <div className="secondHomeLevelSlider">
                                <div className="futureTitle text-start mb-5">
                                    <span className="d-block">{translate(props.lang, "马来西亚第二家园级别")}</span>
                                </div>
                                <Slider {...settings2} ref={slider} arrows={false}>
                                    <div className="text-left px-3 px-md-5 py-5 py-md-5 secondHomeCarouselItem" id="secondHomeCarouselItemHeight">
                                        <div className="futureSliderTitle text-left px-3 px-lg-0">
                                            <span className="d-block mb-4">{translate(props.lang, "白银级")}</span>
                                        </div>
                                        <div className="">
                                            <Row className="justify-content-start align-items-start mx-0 mb-2">
                                                <Col lg={4} md={4} sm={5} xs={5}>
                                                    <div className="d-flex justify-content-start align-items-start">
                                                        <div className="levelPoint mr-3 mr-md-4 mt-1" />
                                                        <span className="d-block levelBgPointTitle">{translate(props.lang, "主申请人年龄")}</span>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={7} xs={7}>
                                                    <span className="d-block levelBgPointDesc">{translate(props.lang, "年满25周岁以上")}</span>
                                                </Col>
                                            </Row>

                                            <Row className="justify-content-start align-items-start mx-0 mb-2">
                                                <Col lg={4} md={4} sm={5} xs={5}>
                                                    <div className="d-flex justify-content-start align-items-start">
                                                        <div className="levelPoint mr-3 mr-md-4 mt-1" />
                                                        <span className="d-block levelBgPointTitle">{translate(props.lang, "携带家属")}</span>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={7} xs={7}>
                                                    <span className="d-block levelBgPointDesc">{translate(props.lang, "配偶，34岁以下子女，双方父母")}</span>
                                                </Col>
                                            </Row>

                                            <Row className="justify-content-start align-items-start mx-0 mb-2">
                                                <Col lg={4} md={4} sm={5} xs={5}>
                                                    <div className="d-flex justify-content-start align-items-start">
                                                        <div className="levelPoint mr-3 mr-md-4 mt-1" />
                                                        <span className="d-block levelBgPointTitle">{translate(props.lang, "存款要求")}</span>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={7} xs={7}>
                                                    <span className="d-block levelBgPointDesc">{translate(props.lang, "15万美金")}</span>
                                                    <span className="d-block levelBgPointDesc2">{translate(props.lang, "*第二年可提取50%用于购房，教育，医疗及马来西亚境内旅游")}</span>
                                                </Col>
                                            </Row>

                                            <Row className="justify-content-start align-items-start mx-0 mb-2">
                                                <Col lg={4} md={4} sm={5} xs={5}>
                                                    <div className="d-flex justify-content-start align-items-start">
                                                        <div className="levelPoint mr-3 mr-md-4 mt-1" />
                                                        <span className="d-block levelBgPointTitle">{translate(props.lang, "收入要求")}</span>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={7} xs={7}>
                                                    <span className="d-block levelBgPointDesc">{translate(props.lang, "取消收入要求")}</span>
                                                </Col>
                                            </Row>

                                            <Row className="justify-content-start align-items-start mx-0 mb-2">
                                                <Col lg={4} md={4} sm={5} xs={5}>
                                                    <div className="d-flex justify-content-start align-items-start">
                                                        <div className="levelPoint mr-3 mr-md-4 mt-1" />
                                                        <span className="d-block levelBgPointTitle">{translate(props.lang, "签证有效期")}</span>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={7} xs={7}>
                                                    <span className="d-block levelBgPointDesc">{translate(props.lang, "5年")}</span>
                                                </Col>
                                            </Row>

                                            <Row className="justify-content-start align-items-start mx-0 mb-2">
                                                <Col lg={4} md={4} sm={5} xs={5}>
                                                    <div className="d-flex justify-content-start align-items-start">
                                                        <div className="levelPoint mr-3 mr-md-4 mt-1" />
                                                        <span className="d-block levelBgPointTitle">{translate(props.lang, "代理费（不包含其他费用）")}</span>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={7} xs={7}>
                                                    <span className="d-block levelBgPointDesc">{translate(props.lang, "4万马币")}</span>
                                                </Col>
                                            </Row>

                                            <Row className="justify-content-start align-items-start mx-0 mb-2">
                                                <Col lg={4} md={4} sm={5} xs={5}>
                                                    <div className="d-flex justify-content-start align-items-start">
                                                        <div className="levelPoint mr-3 mr-md-4 mt-1" />
                                                        <span className="d-block levelBgPointTitle">{translate(props.lang, "购房总价要求")}</span>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={7} xs={7}>
                                                    <span className="d-block levelBgPointDesc">{translate(props.lang, "不低于60万马币")}</span>
                                                    <span className="d-block levelBgPointDesc2">{translate(props.lang, "*必须购买房产，持有10年可出售，若想提出售需购买更高总价房产")}</span>
                                                </Col>
                                            </Row>

                                            <Row className="justify-content-start align-items-start mx-0 mb-2">
                                                <Col lg={4} md={4} sm={5} xs={5}>
                                                    <div className="d-flex justify-content-start align-items-start">
                                                        <div className="levelPoint mr-3 mr-md-4 mt-1" />
                                                        <span className="d-block levelBgPointTitle">{translate(props.lang, "最低居住期限")}</span>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={7} xs={7}>
                                                    <span className="d-block levelBgPointDesc">{translate(props.lang, "每年至少90天；可家庭成员累计")}</span>
                                                </Col>
                                            </Row>

                                            <Row className="justify-content-start align-items-start mx-0 mb-2">
                                                <Col lg={4} md={4} sm={5} xs={5}>
                                                    <div className="d-flex justify-content-start align-items-start">
                                                        <div className="levelPoint mr-3 mr-md-4 mt-1" />
                                                        <span className="d-block levelBgPointTitle">{translate(props.lang, "经商或工作")}</span>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={7} xs={7}>
                                                    <span className="d-block levelBgPointDesc">{translate(props.lang, "不可")}</span>
                                                </Col>
                                            </Row>

                                            <Row className="justify-content-start align-items-start mx-0 mb-2">
                                                <Col lg={4} md={4} sm={5} xs={5}>
                                                    <div className="d-flex justify-content-start align-items-start">
                                                        <div className="levelPoint mr-3 mr-md-4 mt-1" />
                                                        <span className="d-block levelBgPointTitle">{translate(props.lang, "子女就读")}</span>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={7} xs={7}>
                                                    <span className="d-block levelBgPointDesc">{translate(props.lang, "可就读国际学校")}</span>
                                                </Col>
                                            </Row>

                                            <Row className="justify-content-start align-items-start mx-0 mb-2">
                                                <Col lg={4} md={4} sm={5} xs={5}>
                                                    <div className="d-flex justify-content-start align-items-start">
                                                        <div className="levelPoint mr-3 mr-md-4 mt-1" />
                                                        <span className="d-block levelBgPointTitle">{translate(props.lang, "看病就医")}</span>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={7} xs={7}>
                                                    <span className="d-block levelBgPointDesc">{translate(props.lang, "可接受长期医疗")}</span>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>

                                    <div className="text-left px-3 px-md-5 py-5 py-md-5 secondHomeCarouselItem">
                                        <div className="futureSliderTitle text-left px-3 px-lg-0">
                                            <span className="d-block mb-4">{translate(props.lang, "黄金级")}</span>
                                        </div>
                                        <div className="">
                                            <Row className="justify-content-start align-items-start mx-0 mb-2">
                                                <Col lg={4} md={4} sm={5} xs={5}>
                                                    <div className="d-flex justify-content-start align-items-start">
                                                        <div className="levelPoint mr-3 mr-md-4 mt-1" />
                                                        <span className="d-block levelBgPointTitle">{translate(props.lang, "主申请人年龄")}</span>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={7} xs={7}>
                                                    <span className="d-block levelBgPointDesc">{translate(props.lang, "年满25周岁以上")}</span>
                                                </Col>
                                            </Row>

                                            <Row className="justify-content-start align-items-start mx-0 mb-2">
                                                <Col lg={4} md={4} sm={5} xs={5}>
                                                    <div className="d-flex justify-content-start align-items-start">
                                                        <div className="levelPoint mr-3 mr-md-4 mt-1" />
                                                        <span className="d-block levelBgPointTitle">{translate(props.lang, "携带家属")}</span>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={7} xs={7}>
                                                    <span className="d-block levelBgPointDesc">{translate(props.lang, "配偶，34岁以下子女，双方父母")}</span>
                                                </Col>
                                            </Row>

                                            <Row className="justify-content-start align-items-start mx-0 mb-2">
                                                <Col lg={4} md={4} sm={5} xs={5}>
                                                    <div className="d-flex justify-content-start align-items-start">
                                                        <div className="levelPoint mr-3 mr-md-4 mt-1" />
                                                        <span className="d-block levelBgPointTitle">{translate(props.lang, "存款要求")}</span>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={7} xs={7}>
                                                    <span className="d-block levelBgPointDesc">{translate(props.lang, "50万美金")}</span>
                                                    <span className="d-block levelBgPointDesc2">{translate(props.lang, "*第二年可提取50%用于购房，教育，医疗及马来西亚境内旅游")}</span>
                                                </Col>
                                            </Row>

                                            <Row className="justify-content-start align-items-start mx-0 mb-2">
                                                <Col lg={4} md={4} sm={5} xs={5}>
                                                    <div className="d-flex justify-content-start align-items-start">
                                                        <div className="levelPoint mr-3 mr-md-4 mt-1" />
                                                        <span className="d-block levelBgPointTitle">{translate(props.lang, "收入要求")}</span>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={7} xs={7}>
                                                    <span className="d-block levelBgPointDesc">{translate(props.lang, "取消收入要求")}</span>
                                                </Col>
                                            </Row>

                                            <Row className="justify-content-start align-items-start mx-0 mb-2">
                                                <Col lg={4} md={4} sm={5} xs={5}>
                                                    <div className="d-flex justify-content-start align-items-start">
                                                        <div className="levelPoint mr-3 mr-md-4 mt-1" />
                                                        <span className="d-block levelBgPointTitle">{translate(props.lang, "签证有效期")}</span>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={7} xs={7}>
                                                    <span className="d-block levelBgPointDesc">{translate(props.lang, "10年")}</span>
                                                </Col>
                                            </Row>

                                            <Row className="justify-content-start align-items-start mx-0 mb-2">
                                                <Col lg={4} md={4} sm={5} xs={5}>
                                                    <div className="d-flex justify-content-start align-items-start">
                                                        <div className="levelPoint mr-3 mr-md-4 mt-1" />
                                                        <span className="d-block levelBgPointTitle">{translate(props.lang, "代理费（不包含其他费用）")}</span>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={7} xs={7}>
                                                    <span className="d-block levelBgPointDesc">{translate(props.lang, "5万5马币")}</span>
                                                </Col>
                                            </Row>

                                            <Row className="justify-content-start align-items-start mx-0 mb-2">
                                                <Col lg={4} md={4} sm={5} xs={5}>
                                                    <div className="d-flex justify-content-start align-items-start">
                                                        <div className="levelPoint mr-3 mr-md-4 mt-1" />
                                                        <span className="d-block levelBgPointTitle">{translate(props.lang, "购房总价要求")}</span>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={7} xs={7}>
                                                    <span className="d-block levelBgPointDesc">{translate(props.lang, "不低于100万马币")}</span>
                                                    <span className="d-block levelBgPointDesc2">{translate(props.lang, "*必须购买房产，持有10年可出售，若想提出售需购买更高总价房产")}</span>
                                                </Col>
                                            </Row>

                                            <Row className="justify-content-start align-items-start mx-0 mb-2">
                                                <Col lg={4} md={4} sm={5} xs={5}>
                                                    <div className="d-flex justify-content-start align-items-start">
                                                        <div className="levelPoint mr-3 mr-md-4 mt-1" />
                                                        <span className="d-block levelBgPointTitle">{translate(props.lang, "最低居住期限")}</span>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={7} xs={7}>
                                                    <span className="d-block levelBgPointDesc">{translate(props.lang, "每年至少90天；可家庭成员累计")}</span>
                                                </Col>
                                            </Row>

                                            <Row className="justify-content-start align-items-start mx-0 mb-2">
                                                <Col lg={4} md={4} sm={5} xs={5}>
                                                    <div className="d-flex justify-content-start align-items-start">
                                                        <div className="levelPoint mr-3 mr-md-4 mt-1" />
                                                        <span className="d-block levelBgPointTitle">{translate(props.lang, "经商或工作")}</span>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={7} xs={7}>
                                                    <span className="d-block levelBgPointDesc">{translate(props.lang, "不可")}</span>
                                                </Col>
                                            </Row>

                                            <Row className="justify-content-start align-items-start mx-0 mb-2">
                                                <Col lg={4} md={4} sm={5} xs={5}>
                                                    <div className="d-flex justify-content-start align-items-start">
                                                        <div className="levelPoint mr-3 mr-md-4 mt-1" />
                                                        <span className="d-block levelBgPointTitle">{translate(props.lang, "子女就读")}</span>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={7} xs={7}>
                                                    <span className="d-block levelBgPointDesc">{translate(props.lang, "可就读国际学校")}</span>
                                                </Col>
                                            </Row>

                                            <Row className="justify-content-start align-items-start mx-0 mb-2">
                                                <Col lg={4} md={4} sm={5} xs={5}>
                                                    <div className="d-flex justify-content-start align-items-start">
                                                        <div className="levelPoint mr-3 mr-md-4 mt-1" />
                                                        <span className="d-block levelBgPointTitle">{translate(props.lang, "看病就医")}</span>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={7} xs={7}>
                                                    <span className="d-block levelBgPointDesc">{translate(props.lang, "可接受长期医疗")}</span>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>

                                    <div className="text-left px-3 px-md-5 py-5 py-md-5 secondHomeCarouselItem">
                                        <div className="futureSliderTitle text-left px-3 px-lg-0">
                                            <span className="d-block mb-4">{translate(props.lang, "铂金级")}</span>
                                        </div>
                                        <div className="">
                                            <Row className="justify-content-start align-items-start mx-0 mb-2">
                                                <Col lg={4} md={4} sm={5} xs={5}>
                                                    <div className="d-flex justify-content-start align-items-start">
                                                        <div className="levelPoint mr-3 mr-md-4 mt-1" />
                                                        <span className="d-block levelBgPointTitle">{translate(props.lang, "主申请人年龄")}</span>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={7} xs={7}>
                                                    <span className="d-block levelBgPointDesc">{translate(props.lang, "年满25周岁以上")}</span>
                                                </Col>
                                            </Row>

                                            <Row className="justify-content-start align-items-start mx-0 mb-2">
                                                <Col lg={4} md={4} sm={5} xs={5}>
                                                    <div className="d-flex justify-content-start align-items-start">
                                                        <div className="levelPoint mr-3 mr-md-4 mt-1" />
                                                        <span className="d-block levelBgPointTitle">{translate(props.lang, "携带家属")}</span>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={7} xs={7}>
                                                    <span className="d-block levelBgPointDesc">{translate(props.lang, "配偶，34岁以下子女，双方父母")}</span>
                                                </Col>
                                            </Row>

                                            <Row className="justify-content-start align-items-start mx-0 mb-2">
                                                <Col lg={4} md={4} sm={5} xs={5}>
                                                    <div className="d-flex justify-content-start align-items-start">
                                                        <div className="levelPoint mr-3 mr-md-4 mt-1" />
                                                        <span className="d-block levelBgPointTitle">{translate(props.lang, "存款要求")}</span>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={7} xs={7}>
                                                    <span className="d-block levelBgPointDesc">{translate(props.lang, "100万美金")}</span>
                                                    <span className="d-block levelBgPointDesc2">{translate(props.lang, "*第二年可提取50%用于购房，教育，医疗及马来西亚境内旅游")}</span>
                                                </Col>
                                            </Row>

                                            <Row className="justify-content-start align-items-start mx-0 mb-2">
                                                <Col lg={4} md={4} sm={5} xs={5}>
                                                    <div className="d-flex justify-content-start align-items-start">
                                                        <div className="levelPoint mr-3 mr-md-4 mt-1" />
                                                        <span className="d-block levelBgPointTitle">{translate(props.lang, "收入要求")}</span>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={7} xs={7}>
                                                    <span className="d-block levelBgPointDesc">{translate(props.lang, "取消收入要求")}</span>
                                                </Col>
                                            </Row>

                                            <Row className="justify-content-start align-items-start mx-0 mb-2">
                                                <Col lg={4} md={4} sm={5} xs={5}>
                                                    <div className="d-flex justify-content-start align-items-start">
                                                        <div className="levelPoint mr-3 mr-md-4 mt-1" />
                                                        <span className="d-block levelBgPointTitle">{translate(props.lang, "签证有效期")}</span>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={7} xs={7}>
                                                    <span className="d-block levelBgPointDesc">{translate(props.lang, "20年")}</span>
                                                </Col>
                                            </Row>

                                            <Row className="justify-content-start align-items-start mx-0 mb-2">
                                                <Col lg={4} md={4} sm={5} xs={5}>
                                                    <div className="d-flex justify-content-start align-items-start">
                                                        <div className="levelPoint mr-3 mr-md-4 mt-1" />
                                                        <span className="d-block levelBgPointTitle">{translate(props.lang, "代理费（不包含其他费用）")}</span>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={7} xs={7}>
                                                    <span className="d-block levelBgPointDesc">{translate(props.lang, "7万马币")}</span>
                                                </Col>
                                            </Row>

                                            <Row className="justify-content-start align-items-start mx-0 mb-2">
                                                <Col lg={4} md={4} sm={5} xs={5}>
                                                    <div className="d-flex justify-content-start align-items-start">
                                                        <div className="levelPoint mr-3 mr-md-4 mt-1" />
                                                        <span className="d-block levelBgPointTitle">{translate(props.lang, "购房总价要求")}</span>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={7} xs={7}>
                                                    <span className="d-block levelBgPointDesc">{translate(props.lang, "不低于200万马币")}</span>
                                                    <span className="d-block levelBgPointDesc2">{translate(props.lang, "*必须购买房产，持有10年可出售，若想提出售需购买更高总价房产")}</span>
                                                </Col>
                                            </Row>

                                            <Row className="justify-content-start align-items-start mx-0 mb-2">
                                                <Col lg={4} md={4} sm={5} xs={5}>
                                                    <div className="d-flex justify-content-start align-items-start">
                                                        <div className="levelPoint mr-3 mr-md-4 mt-1" />
                                                        <span className="d-block levelBgPointTitle">{translate(props.lang, "最低居住期限")}</span>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={7} xs={7}>
                                                    <span className="d-block levelBgPointDesc">{translate(props.lang, "每年至少90天；可家庭成员累计")}</span>
                                                </Col>
                                            </Row>

                                            <Row className="justify-content-start align-items-start mx-0 mb-2">
                                                <Col lg={4} md={4} sm={5} xs={5}>
                                                    <div className="d-flex justify-content-start align-items-start">
                                                        <div className="levelPoint mr-3 mr-md-4 mt-1" />
                                                        <span className="d-block levelBgPointTitle">{translate(props.lang, "经商或工作")}</span>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={7} xs={7}>
                                                    <span className="d-block levelBgPointDesc">{translate(props.lang, "可以")}</span>
                                                </Col>
                                            </Row>

                                            <Row className="justify-content-start align-items-start mx-0 mb-2">
                                                <Col lg={4} md={4} sm={5} xs={5}>
                                                    <div className="d-flex justify-content-start align-items-start">
                                                        <div className="levelPoint mr-3 mr-md-4 mt-1" />
                                                        <span className="d-block levelBgPointTitle">{translate(props.lang, "子女就读")}</span>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={7} xs={7}>
                                                    <span className="d-block levelBgPointDesc">{translate(props.lang, "可就读国际学校")}</span>
                                                </Col>
                                            </Row>

                                            <Row className="justify-content-start align-items-start mx-0 mb-2">
                                                <Col lg={4} md={4} sm={5} xs={5}>
                                                    <div className="d-flex justify-content-start align-items-start">
                                                        <div className="levelPoint mr-3 mr-md-4 mt-1" />
                                                        <span className="d-block levelBgPointTitle">{translate(props.lang, "看病就医")}</span>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={7} xs={7}>
                                                    <span className="d-block levelBgPointDesc">{translate(props.lang, "可接受长期医疗")}</span>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>

                                    <div className="text-left px-3 px-md-5 py-5 py-md-5 secondHomeCarouselItem">
                                        <div className="futureSliderTitle text-left px-3 px-lg-0">
                                            <span className="d-block mb-4">{translate(props.lang, "经济特区（柔佛特区）")}</span>
                                        </div>
                                        <div className="">
                                            <Row className="justify-content-start align-items-start mx-0 mb-2">
                                                <Col lg={4} md={4} sm={5} xs={5}>
                                                    <div className="d-flex justify-content-start align-items-start">
                                                        <div className="levelPoint mr-3 mr-md-4 mt-1" />
                                                        <span className="d-block levelBgPointTitle">{translate(props.lang, "主申请人年龄")}</span>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={7} xs={7}>
                                                    <span className="d-block levelBgPointDesc">{translate(props.lang, "年满21周岁以上")}</span>
                                                </Col>
                                            </Row>

                                            <Row className="justify-content-start align-items-start mx-0 mb-2">
                                                <Col lg={4} md={4} sm={5} xs={5}>
                                                    <div className="d-flex justify-content-start align-items-start">
                                                        <div className="levelPoint mr-3 mr-md-4 mt-1" />
                                                        <span className="d-block levelBgPointTitle">{translate(props.lang, "携带家属")}</span>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={7} xs={7}>
                                                    <span className="d-block levelBgPointDesc">{translate(props.lang, "配偶，34岁以下子女，双方父母")}</span>
                                                </Col>
                                            </Row>

                                            <Row className="justify-content-start align-items-start mx-0 mb-2">
                                                <Col lg={4} md={4} sm={5} xs={5}>
                                                    <div className="d-flex justify-content-start align-items-start">
                                                        <div className="levelPoint mr-3 mr-md-4 mt-1" />
                                                        <span className="d-block levelBgPointTitle">{translate(props.lang, "存款要求")}</span>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={7} xs={7}>
                                                    <span className="d-block levelBgPointDesc">{translate(props.lang, "21 - 49岁（6.5万美金）；50岁以上（3.2万美金）")}</span>
                                                    <span className="d-block levelBgPointDesc2">{translate(props.lang, "*第二年可提取50%用于购房，教育，医疗及马来西亚境内旅游")}</span>
                                                </Col>
                                            </Row>

                                            <Row className="justify-content-start align-items-start mx-0 mb-2">
                                                <Col lg={4} md={4} sm={5} xs={5}>
                                                    <div className="d-flex justify-content-start align-items-start">
                                                        <div className="levelPoint mr-3 mr-md-4 mt-1" />
                                                        <span className="d-block levelBgPointTitle">{translate(props.lang, "收入要求")}</span>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={7} xs={7}>
                                                    <span className="d-block levelBgPointDesc">{translate(props.lang, "取消收入要求")}</span>
                                                </Col>
                                            </Row>

                                            <Row className="justify-content-start align-items-start mx-0 mb-2">
                                                <Col lg={4} md={4} sm={5} xs={5}>
                                                    <div className="d-flex justify-content-start align-items-start">
                                                        <div className="levelPoint mr-3 mr-md-4 mt-1" />
                                                        <span className="d-block levelBgPointTitle">{translate(props.lang, "签证有效期")}</span>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={7} xs={7}>
                                                    <span className="d-block levelBgPointDesc">{translate(props.lang, "10年")}</span>
                                                </Col>
                                            </Row>

                                            <Row className="justify-content-start align-items-start mx-0 mb-2">
                                                <Col lg={4} md={4} sm={5} xs={5}>
                                                    <div className="d-flex justify-content-start align-items-start">
                                                        <div className="levelPoint mr-3 mr-md-4 mt-1" />
                                                        <span className="d-block levelBgPointTitle">{translate(props.lang, "代理费（不包含其他费用）")}</span>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={7} xs={7}>
                                                    <span className="d-block levelBgPointDesc">{translate(props.lang, "4万马币")}</span>
                                                </Col>
                                            </Row>

                                            <Row className="justify-content-start align-items-start mx-0 mb-2">
                                                <Col lg={4} md={4} sm={5} xs={5}>
                                                    <div className="d-flex justify-content-start align-items-start">
                                                        <div className="levelPoint mr-3 mr-md-4 mt-1" />
                                                        <span className="d-block levelBgPointTitle">{translate(props.lang, "购房总价要求")}</span>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={7} xs={7}>
                                                    <span className="d-block levelBgPointDesc">{translate(props.lang, "以相关州属政策为准")}</span>
                                                    <span className="d-block levelBgPointDesc2">{translate(props.lang, "*必须购买房产，持有10年可出售，若想提出售需购买更高总价房产")}</span>
                                                </Col>
                                            </Row>

                                            <Row className="justify-content-start align-items-start mx-0 mb-2">
                                                <Col lg={4} md={4} sm={5} xs={5}>
                                                    <div className="d-flex justify-content-start align-items-start">
                                                        <div className="levelPoint mr-3 mr-md-4 mt-1" />
                                                        <span className="d-block levelBgPointTitle">{translate(props.lang, "最低居住期限")}</span>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={7} xs={7}>
                                                    <span className="d-block levelBgPointDesc">{translate(props.lang, "每年至少90天；可家庭成员累计")}</span>
                                                </Col>
                                            </Row>

                                            <Row className="justify-content-start align-items-start mx-0 mb-2">
                                                <Col lg={4} md={4} sm={5} xs={5}>
                                                    <div className="d-flex justify-content-start align-items-start">
                                                        <div className="levelPoint mr-3 mr-md-4 mt-1" />
                                                        <span className="d-block levelBgPointTitle">{translate(props.lang, "经商或工作")}</span>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={7} xs={7}>
                                                    <span className="d-block levelBgPointDesc">{translate(props.lang, "不可")}</span>
                                                </Col>
                                            </Row>

                                            <Row className="justify-content-start align-items-start mx-0 mb-2">
                                                <Col lg={4} md={4} sm={5} xs={5}>
                                                    <div className="d-flex justify-content-start align-items-start">
                                                        <div className="levelPoint mr-3 mr-md-4 mt-1" />
                                                        <span className="d-block levelBgPointTitle">{translate(props.lang, "子女就读")}</span>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={7} xs={7}>
                                                    <span className="d-block levelBgPointDesc">{translate(props.lang, "可就读国际学校")}</span>
                                                </Col>
                                            </Row>

                                            <Row className="justify-content-start align-items-start mx-0 mb-2">
                                                <Col lg={4} md={4} sm={5} xs={5}>
                                                    <div className="d-flex justify-content-start align-items-start">
                                                        <div className="levelPoint mr-3 mr-md-4 mt-1" />
                                                        <span className="d-block levelBgPointTitle">{translate(props.lang, "看病就医")}</span>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={7} xs={7}>
                                                    <span className="d-block levelBgPointDesc">{translate(props.lang, "可接受长期医疗")}</span>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </Slider>

                                <div className="">
                                    <div className="d-flex justify-content-center justify-content-lg-start align-items-center position-relative arrowSection">
                                        <LazyLoadImage
                                            src={
                                                "/assets/img/home/left.webp"
                                            }
                                            alt="left"
                                            onClick={() => slider?.current?.slickPrev()}
                                            style={{ cursor: "pointer" }}
                                            className="leftSliderImg"
                                        />
                                        <LazyLoadImage
                                            src={
                                                "assets/img/home/right.webp"
                                            }
                                            alt="right"
                                            onClick={() => slider?.current?.slickNext()}
                                            style={{ cursor: "pointer" }}
                                            className="rightSliderImg"
                                        />
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
}

const mapStateToProps = state => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
}
export default connect(mapStateToProps)(SecondHomeLevel);